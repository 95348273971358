<template>
  <CRow class="justify-content-center">
    <CCol lg="4" md="5" col="12">
      <CCard>
        <CCardBody>
          <CForm>
            <TMessage content="Login" size="h3" />
            <TMessage
              content="Sign in to your account"
              color="muted"
              class="mb-3"
            />
            <TInputEmail :value.sync="email" class="mb-3" />
            <TInputPassword :value.sync="password" viewable />
            <CRow>
              <CCol col="6" class="text-left">
                <CButton color="primary" @click="loginPW">
                  <TMessage content="Login" />
                </CButton>
              </CCol>
              <CCol col="6" class="text-right">
                <CButton
                  color="link"
                  class="px-0"
                  @click="$redirect.toForgotPassword()"
                >
                  <TMessage content="Forgot password">
                    <template #suffix>?</template>
                  </TMessage>
                </CButton>
              </CCol>
            </CRow>
          </CForm>
          <CRow>
            <CCol class="text-center">
              <hr />
              <CButton color="youtube" @click="loginSocial('google')">
                <CIcon name="cib-google" />
              </CButton>
              <CButton class="ml-2" color="dark" @click="loginSocial('apple')">
                <CIcon name="cib-apple" />
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    loginPW() {
      this.$store
        .dispatch("auth.login.password", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$redirect.toHome();
        });
    },
    loginSocial(provider) {
      this.$store.dispatch("auth.login.social", provider).then(() => {
        this.$redirect.toHome();
      });
    },
  },
};
</script>
